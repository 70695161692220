<template>
  <div>
    <ProviderProfileCard :provider="provider" />
    <ProviderDocumentationCard :provider="provider" />
    <ProviderBillingCard :provider="provider" />
    <ProviderAssociatedResourceTabs :provider-id="providerId" />
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import ApiRestService from '@/api/base-api'
import ProviderBillingCard from '@/components/providers/card/ProviderBillingCard.vue'
import ProviderProfileCard from '@/components/providers/card/ProviderProfileCard.vue'
import ProviderAssociatedResourceTabs from '@/components/providers/tabs/ProviderAssociatedResourceTabs.vue'
import ProviderDocumentationCard from '@/components/providers/card/ProviderDocumentationCard.vue'

export default {
  components: {
    ProviderAssociatedResourceTabs,
    ProviderProfileCard,
    ProviderDocumentationCard,
    ProviderBillingCard,
  },
  data() {
    return {
      provider: null,
    }
  },
  computed: {
    providerId() {
      const id = parseInt(this.$route.params.id, 10)
      return Number.isNaN(id) ? null : id
    },
  },
  mounted() {
    this.loadProvider()
  },
  methods: {
    ...mapActions('app', ['setLoading']),
    async loadProvider() {
      try {
        this.setLoading(true)
        const response = await ApiRestService.get(this.$route.params.id, this.$data.$constants.RESOURCES.RESOURCE_PROVIDERS)
        this.provider = response.data
      } finally {
        this.setLoading(false)
      }
    },
  },
}
</script>

<style scoped>
</style>
