<template>
    <BaseDocumentationCard
      v-model="documentation"
      :resource="provider"
      :delete-end-point="deleteEndPoint"
      :update-end-point="updateEndPoint"
      :observations="provider ? provider.observations : ''"
    />
  </template>
  
  <script>
  import BaseDocumentationCard from '@/components/ui/card/BaseDocumentationCard.vue'
  
  export default {
    name: 'ProviderDocumentationCard',
    components: { BaseDocumentationCard },
    props: {
      provider: {
        type: Object,
        default: null,
      },
    },
    computed: {
      documentation: {
        get() {
          if (!this.provider) {
            return []
          }
  
          return this.provider.documentation
        },
        set(value) {
          this.provider.documentation = value
        },
      },
      deleteEndPoint() {
        return this.provider ? `/providers/delete_doc/${this.provider.id}` : null
      },
      updateEndPoint() {
        return this.provider ? `/providers/${this.provider.id}` : null
      },
    },
  }
  </script>