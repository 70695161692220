<template>
  <BaseCard
    actions-visible
    :resource="$data.$constants.RESOURCES.RESOURCE_PROVIDERS"
    :footer-visible="false"
    @edit-icon-click="$router.push({ name: 'editProvider', params: { id: $route.params.id } })"
    @delete-confirmation="handleDeleteConfirmation"
  >
    <template #title>
      <b-img
        v-if="provider && provider.image"
        :src="provider.image.path"
        :height="80"
        alt="profile image"
      />
      <span class="text-capitalize text-dark">{{ provider ? provider.name : '' }}</span>
      <StatusBadge
        :status="provider ? provider.status.name : null"
        class="mx-1 font-small-3"
      />
      <div>
        <small class="font-weight-bold">Fecha de registro:</small>
        <div>
          <small class="text-light font-weight-light">
            {{ provider && provider.record_date ? provider.record_date : 'Sin fecha de registro' }}
          </small>
        </div>
      </div>
    </template>
    <ProfilePanel :profile="providerProfile" />
  </BaseCard>
</template>

<script>
import { mapActions } from 'vuex'
import ApiRestService from '@/api/base-api'
import ProfilePanel from '@/components/ui/panel/ProfilePanel.vue'
import StatusBadge from '@/components/status/badge/StatusBadge.vue'
import BaseCard from '@/components/ui/card/BaseCard.vue'

export default {
  name: 'ProviderProfileCard',
  components: { BaseCard, StatusBadge, ProfilePanel },
  props: {
    provider: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    providerProfile() {
      if (!this.provider) {
        return []
      }

      return [
        { label: 'Número', value: this.provider.number || 'Sin número' },
        { label: 'Nombre fiscal', value: this.provider.name || 'Sin nombre fiscal' },
        { label: 'Nombre comercial', value: this.provider.commercial_name || 'Sin nombre comercial' },
        { label: 'Dirección postal', value: this.provider.address || 'Sin dirección postal' },
        { label: 'CP', value: this.provider.post_code || 'Sin código postal' },
        { label: 'Ciudad', value: this.provider.population || 'Sin ciudad' },
        { label: 'Provincia', value: this.provider.province ? this.provider.province.name : 'Sin provincia' },
        { label: 'País', value: this.provider.country ? this.provider.country.name : 'Sin país' },
        { label: 'CIF', value: this.provider.cif || 'Sin CIF' },
        { label: 'Teléfono', value: this.provider.phone || 'Sin teléfono' },
        { label: 'EMAIL', value: this.provider.email || 'Sin email' },
        { label: 'Actividad', value: this.provider?.activity?.name || 'Sin actividad' },
        { label: 'Descripción / Observaciones', value: this.provider.observations || 'Sin Información', type: 'observations' },
      ]
    },
  },
  methods: {
    ...mapActions('app', ['setLoading']),
    async handleDeleteConfirmation() {
      if (!this.provider) {
        return
      }

      const response = await this.$modal.show({
        title: '¿Está seguro?',
        text: `Se borrará el registro ${this.provider.name}.`,
      })

      if (!response.isConfirmed) {
        return
      }

      try {
        this.setLoading(true)
        await ApiRestService.delete(this.provider.id, this.$data.$constants.RESOURCES.RESOURCE_PROVIDERS)
        await this.$router.push('/providers')
      } finally {
        this.setLoading(false)
      }
    },
  },
}
</script>

<style scoped>
</style>
