<template>
  <b-card no-body>
    <b-row
      class="p-2"
      align-v="center"
      align-h="between"
    >
      <b-col
        class="font-weight-bold d-flex align-items-center"
        cols="auto"
      >
        <span class="text-uppercase">información bancaria y de pago</span>
      </b-col>
      <b-col cols="auto">
        <b-link @click="billingCollapse = !billingCollapse">
          <FeatherIcon
            v-if="!billingCollapse"
            icon="ChevronDownIcon"
          />
          <FeatherIcon
            v-else
            icon="ChevronUpIcon"
          />
        </b-link>
      </b-col>
    </b-row>
    <hr class="m-0">
    <b-collapse v-model="billingCollapse">
      <b-row
        class="p-2"
        align-v="start"
      >
        <b-col
          v-for="providerBillingField in providerBillingFields"
          :key="providerBillingField.label"
          xl="2"
          lg="3"
          md="4"
          cols="6"
          class="pb-1"
        >
          <div class="font-weight-bold">
            {{ providerBillingField.label }}:
          </div>
          <div class="text-light">
            {{ providerBillingField.value }}
          </div>
        </b-col>
      </b-row>
    </b-collapse>
  </b-card>
</template>

<script>
export default {
  name: 'ProviderBillingCard',
  props: {
    provider: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      billingCollapse: true,
    }
  },
  computed: {
    providerBillingFields() {
      if (!this.provider) {
        return []
      }

      return [
        { label: 'Forma de pago', value: this.paymentType },
        { label: 'IBAN', value: this.iban },
        { label: 'SWIFT', value: this.swift },
        { label: 'IRPF', value: this.irpf },
        { label: 'IVA', value: this.iva },
        { label: 'RECARGO', value: this.surcharge },
      ]
    },
    paymentType() {
      if (!this.provider.type_payment || !this.provider.type_payment.name || !this.provider.type_payment.name.trim()) {
        return 'Sin Forma de pago'
      }

      return this.provider.type_payment.name
    },
    iban() {
      if (!this.provider.iban || !this.provider.iban.trim()) {
        return 'Sin datos del IBAN'
      }

      return this.provider.iban
    },
    swift() {
      if (!this.provider.swift || !this.provider.swift.trim()) {
        return 'Sin datos del SWIFT'
      }

      return this.provider.swift
    },
    irpf() {
      if (!this.provider.irpf_percent) {
        return 'Sin datos del IRPF'
      }

      return `${this.provider.irpf_percent} %`
    },
    iva() {
      if (!this.provider.tax || !this.provider.tax.name || !this.provider.tax.name.trim()) {
        return 'Sin datos del IVA'
      }

      return this.provider.tax.name
    },
    surcharge() {
      if (!this.provider.surcharge || !this.provider.surcharge.trim()) {
        return 'Sin datos del Recargo'
      }

      return `${this.provider.surcharge} %`
    },
  },
}
</script>

<style scoped>
</style>
